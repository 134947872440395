export type List = { text: string; value: number };

export type WorkSchedule = {
  weekday?: string;
  shiftPatternId?: number;
  shiftPatternName?: string;
  startTime?: string;
  endTime?: string;
  restTime?: string;
  workTime?: string;
  diffTime?: string;
  week1?: string;
  week2?: string;
  week3?: string;
  week4?: string;
  week5?: string;
};

export const WeekDay = {
  Sunday: "1",
  Monday: "2",
  Tuesday: "3",
  Wednesday: "4",
  Thursday: "5",
  Friday: "6",
  Saturday: "7"
} as const;
export type WeekDay = typeof WeekDay[keyof typeof WeekDay];
